






import useAbstract from "@/use/abstract";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(_, { root }) {
    const { typeItems } = useAbstract({ root });
    return { typeItems };
  },
});
